import styled, { keyframes } from "styled-components";
import { ISlide } from "./model/ISlide";

interface SlideProps {
  item: ISlide | undefined;
  currentIndex: number;
}

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;
const fadeOut = keyframes`
  100% { opacity: 1; }
  0% { opacity: 0; }
`;

const SlideContainerIn = styled.div`
  animation: ${fadeIn} 0.6s ease-in;
  margin-top: 40px;
  width: 100%;
`;

const SlideContainerOut = styled.div`
  animation: ${fadeOut} 0.6s ease-out;
  margin-top: 40px;
  width: 100%;
`;

const Description = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  margin-bottom: 30px;
`;

const SlideImage = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
`;

export const Slide = ({ item, currentIndex }: SlideProps) => {
  if (!item) return <div></div>;
  if (item.index !== currentIndex) {
    return (
      <SlideContainerOut>
        <Description>{item.description}</Description>
        <SlideImage src={"img/" + item.image.src} />;
      </SlideContainerOut>
    );
  }
  return (
    <SlideContainerIn>
      <Description>{item.description}</Description>
      <SlideImage src={"img/" + item.image.src} />;
    </SlideContainerIn>
  );
};
