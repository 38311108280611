import styled from "styled-components";

export const Header = () => {
  return (
    <Container>
      <Logo src={"img/mr_platform_300x50.png"} />
      <HeaderHeadline>
        Platforma internetowa<br />do badań marketingowych
      </HeaderHeadline>
    </Container>
  );
};

const Container = styled.div`
    padding: 20px;
    background-color:rgba(0, 0, 0, 0.7);
`
const Logo = styled.img`
    display: block;
    margin-left: auto;
    margin-right: auto;
`
const HeaderHeadline = styled.div`
    margin-top: 20px;
    color: #b2f8f8;
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
`
