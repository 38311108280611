import { useEffect, useState } from "react";
import styled from "styled-components";
import { Header } from "./Header";
import { ISlide } from "./model/ISlide";
import { getData } from "./model/SlideService";
import { Slide } from "./Slide";

const SlideShowContainer = styled.div`
    position: absolute;
    width: 100%;
    top: 30px;
    overflow: hidden;
    z-index: 2;
`

export const SlideShow = () => {
  const [itemList, setItemList] = useState<Array<ISlide>>([]);
  const [item, setItem] = useState<ISlide | undefined>(undefined);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  useEffect(() => {
    const data = getData();
    setItemList(data);
  }, []);

  useEffect(() => {
    let slideInterval: NodeJS.Timeout | undefined = undefined;
    if (itemList.length > 1) {
      slideInterval = setInterval(() => {
        const index = currentIndex < itemList.length - 1 ? currentIndex + 1 : 0;
        setCurrentIndex(index);
      }, 5000);
    }
    return () => {
        if (slideInterval) clearInterval(slideInterval);
    };
  }, [itemList.length, currentIndex]);

  useEffect(() => {
    if (itemList.length > 0) {
      setItem(itemList[currentIndex]);
    }
  }, [currentIndex, itemList]);


  if (!item) return <div style={{ color: "yellow" }}>loading...</div>;

  return (
    <SlideShowContainer>
      <Header />
      <Slide item={item} currentIndex={currentIndex} />
    </SlideShowContainer>
  );
};
