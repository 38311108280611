import React, { ReactElement } from 'react'
import styled from 'styled-components';
import BigData from "../../assets/video.mp4";

export const FullScreenVideo = styled.video`
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    top: 0;
    z-index: 1;
`

export default function BgVideo(): ReactElement {
    // video from:
    // https://www.pexels.com/pl-pl/video/cyfrowa-projekcja-abstrakcyjnych-linii-geometrycznych-3129671/
    // <FullScreenVideo autoPlay loop muted>
    return (
        <FullScreenVideo autoPlay loop muted>
            <source src={BigData} type="video/mp4" />
        </FullScreenVideo>
    )

}
