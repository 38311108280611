import './App.css';
import BgVideo from './component/bg/BgVideo';
import { SlideShow } from './component/slideshow/SlideShow';

function App() {
  return (
    <div className="App">
      <BgVideo />
      <SlideShow />
    </div>
  );
}

export default App;
